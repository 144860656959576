import { Box } from '@mui/material';
import HomePage from './HomePage/HomePage';
import "./App.css"

function App() {
  return (
    <div
    style={{
      position: "absolute",
      width: "100%",
      height: "100vh",
      top: "0",
      bottom: "0",
      left: "0",
      margin: "0",
      padding: "0"
    }}
  >
      <HomePage />
    </div>
  );
}

export default App;
