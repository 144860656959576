import React, { useRef, useState } from "react";
import { Alert, Backdrop, Box, Button, CircularProgress, Snackbar } from "@mui/material";
import ClassConnectLogo from "../images/Class Connect Logo.png";
import EmailInput from './EmailInput';
import "./HomePage.css";
import axios from 'axios';
import { useMediaQuery } from '@mui/material';

const scriptUrl = "https://script.google.com/macros/s/AKfycbx-LPOdEjvj06-rdNTPAfUSqvVd2XBFhnkic6l12E7N1Upx4UKGX2hDprmzx-Z6emwI/exec";

const HomePage = () => {
    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const formRef = useRef(null);

    const isSmallScreen = useMediaQuery('(max-width:637px)');

    const checkIsValidEmail = (email) => {
        // What assumptions can we make about student emails?
        const emailRegex = /^[a-zA-Z0-9.-]{1,}@(\w+\.)*\bstanford\.edu\b$/;
        return emailRegex.test(email);
    }

    const handleEmailChange = (e) => {
        const inputEmail = e.target.value;
        const isValid = checkIsValidEmail(inputEmail);
        setEmail(inputEmail);
        setIsValidEmail(isValid);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        fetch(scriptUrl, { method: "POST", body: new FormData(formRef.current) }
        )
            .then(res => {
                console.log("Email submitted: ", email);
                setLoading(false);
                setOpenSnackbar(true);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    }


    return (
        <Box className="pageContainer">
            {isSmallScreen ? <Box /> : <Box className="decorativeTextRight">What classes are you taking this qua</Box>}
            {isSmallScreen ? <Box /> : <Box className="decorativeTextRight" sx={{ marginTop: "1%", marginRight: "20%" }}>Let's make a study group.</Box>}
            <Box className="headerContainer">
                <Box className="stanfordLogo">Stanford</Box>
                <Box className="line"></Box>
                <img src={ClassConnectLogo} alt="Class Connect Logo"></img>
                {isSmallScreen ? <Box /> :
                    <Box className="classConnectLogo">
                        <Box className="h1">Class</Box>
                        <Box className="h1">Connect</Box>
                    </Box>
                }
            </Box>
            <Box className="bodyContainer">
                <Box className="h1">Find friends in any class.</Box>
                <Box className="h2">Leverage your network to meet new study partners.</Box>
                <Box className="h2">Plan your schedule together.</Box>
                <Box className="h2">Never take a class alone.</Box>
            </Box>
            <Box className="h3" sx={{ marginTop: "10px", textAlign: "center"}}>Sign up with your Stanford email for early access.</Box>

            <form method="post" ref={formRef} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <input className="emailInput" type="email" name="email" value={email} onChange={handleEmailChange} placeholder="Enter valid Stanford email"/>
                <Button style={{marginTop: 10, backgroundColor: "#ffffff80"}} type="submit" variant="contained" disabled={!isValidEmail} onClick={handleSubmit} ref={formRef} sx={{fontFamily: "Open Sans, sans-serif"}}>SUBMIT</Button>
            </form>

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Email submitted successfully!
                </Alert>
            </Snackbar>

            {isSmallScreen ? <Box /> : <Box className="decorativeTextLeft">Do you know anyone in CS161?</Box>}
            {isSmallScreen ? <Box /> : <Box className="decorativeTextLeft" sx={{ marginBottom: "3%", marginTop: "1%", marginLeft: "20%" }}>You're in this class?!</Box>}
        </Box>
    )
};

export default HomePage;