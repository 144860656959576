import React from 'react';

function EmailInput() {
  return (
    <div style={{ position: 'relative', marginBottom: '20px' }}>
      <input
        type="text"
        placeholder="Enter email address..."
        style={{
          borderRadius: '20px',
          borderWidth: '1px',
          borderColor: '#000',
          padding: '10px',
          width: '200px',
          background: 'rgba(255, 255, 255, 0.5)', // 50% opacity white
        }}
      />
      <button
        type="submit"
        style={{
          position: 'absolute',
          right: '5px',
          top: '5px',
          background: 'transparent',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        <span style={{ color: 'red' }}>⮕</span> {/* Red arrow */}
      </button>
    </div>
  );
}

export default EmailInput;
